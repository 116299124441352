import React from "react";
import { useTranslation } from "react-i18next";

import Notification from "./Notification/Notification";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const NotificationsBody = (props) => {
  const { t } = useTranslation();
  const {
    records,
    messagesLimit,
    isLimit,
    selectedRecords,
    notificationFilter,
    addSelectedRecord,
    removeSelectedRecord,
    selectAllRecords,
    removeAllSelectedRecords,
    onAddOneMorePartNotifications,
    transferSigleRecordToArchive,
    transferSigleRecordToDelete,
    onRedirectToSingleOrder,
    onRedirectToSingleProduct,
    loading,
  } = props;

  return (
    <div className="notifications-block-body">
      <div className="notifications-block-body-header">
        <div className="notf-checkbox">
          <input
            type="checkbox"
            checked={
              records &&
              selectedRecords &&
              records.length === selectedRecords.length
            }
            onChange={
              records.length === selectedRecords.length
                ? removeAllSelectedRecords
                : selectAllRecords
            }
          />
        </div>
        <div className="notf-message-type" />
        <div className="notf-message">Повідомлення</div>
        <div className="notf-prioritet">Приорітет</div>
        <div className="notf-date">Отримано</div>
        <div className="notf-setting" />
      </div>
      <div className="notifications-block-body-items">
        {loading && (
          <div className="loading-item">
            <Spinner />
          </div>
        )}
        {!loading &&
          records &&
          records.length > 0 &&
          records.map((el) => (
            <Notification
              item={el}
              key={el.id}
              notificationFilter={notificationFilter}
              selectedRecords={selectedRecords}
              addSelectedRecord={addSelectedRecord}
              removeSelectedRecord={removeSelectedRecord}
              transferSigleRecordToArchive={transferSigleRecordToArchive}
              transferSigleRecordToDelete={transferSigleRecordToDelete}
              onRedirectToSingleOrder={onRedirectToSingleOrder}
              onRedirectToSingleProduct={onRedirectToSingleProduct}
            />
          ))}
        {records && !isLimit && !loading && (
          <div
            className="load-next-part"
            onClick={onAddOneMorePartNotifications}
          >
            <span>{`Загрузить следующие ${messagesLimit} сообщений`}</span>
          </div>
        )}
        {records && records.length === 0 && !loading && (
          <div className="empty-item">
            <span>{t("Сообщений не найдено")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsBody;
