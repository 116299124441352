import { isJsonString } from "../common/additionalFuncs";

export const parseShopSettings = (shops) => {
  shops.forEach(shop => {
    if(shop.settings && isJsonString(shop.settings)) {
      shop.settings = JSON.parse(shop.settings);
    } else {
      shop.settings = {};
    }
  })

  return shops;
}

export const getWorkTimeInit = (t) => {
  return [
    {
      on: true,
      dayOfWeek: "1",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d1"),
    },
    {
      on: true,
      dayOfWeek: "2",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d2"),
    },
    {
      on: true,
      dayOfWeek: "3",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d3"),
    },
    {
      on: true,
      dayOfWeek: "4",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d4"),
    },
    {
      on: true,
      dayOfWeek: "5",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d5"),
    },
    {
      on: true,
      dayOfWeek: "6",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d6"),
    },
    {
      on: true,
      dayOfWeek: "7",
      from: "08:00:00",
      to: "20:00:00",
      name: t("merchant.merchantRows.shopInfo.openHours.days.d7"),
    },
  ];
};

export const getWeekDays = (editedShop, t) => {
  const workTimeInit = getWorkTimeInit(t);
  if (!("settings" in editedShop)) {
    return { weekDays: workTimeInit, exclusionDays: [] };
  } else {
    const settings = editedShop["settings"];
    if (!(settings["open_hours"])) {
      return { weekDays: workTimeInit, exclusionDays: [] };
    }
    // if received "open_hours" key
    else {
      // get exclusionDays
      const respExclusionDays = settings["open_hours"].filter(
        (el) => "date" in el,
      );

      // get weekDays
      const respWeekDays = settings["open_hours"].filter(
        (el) => "dayOfWeek" in el,
      );
      const resultWeekDays = [];
      workTimeInit.forEach((dayInit) => {
        const dayAtData = respWeekDays.find(
          (dayObj) => dayObj.dayOfWeek === dayInit.dayOfWeek,
        );
        if (dayAtData) {
          resultWeekDays.push({ ...dayInit, ...dayAtData });
        } else {
          resultWeekDays.push(dayInit);
        }
      });
      return { weekDays: resultWeekDays, exclusionDays: respExclusionDays };
    }
  }
};
