import React from "react";
import moment from "moment-timezone";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";

// icon
import { ReactComponent as ArchiveIcon } from "../../../../../assets/images/components/Notifications/archive.svg";
import { ReactComponent as TrashGrayIcon } from "../../../../../assets/images/components/Notifications/trash-gray.svg";

// icons
import { ReactComponent as EndsStuffIcon } from "../../../../../assets/images/components/Notifications/message-type/ends-stuff.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/images/components/Notifications/message-type/info.svg";
import { ReactComponent as LimitIcon } from "../../../../../assets/images/components/Notifications/message-type/limit.svg";
import { ReactComponent as NewOrderIcon } from "../../../../../assets/images/components/Notifications/message-type/new_order.svg";
import { ReactComponent as PaidStuffIcon } from "../../../../../assets/images/components/Notifications/message-type/paid-stuff.svg";

const messageIcon = {
  ends: <EndsStuffIcon style={{ width: "23px", height: "23px" }} />,
  info: <InfoIcon style={{ width: "23px", height: "23px" }} />,
  limit: <LimitIcon style={{ width: "23px", height: "23px" }} />,
  payed: <PaidStuffIcon style={{ width: "23px", height: "23px" }} />,
  "order-new": <NewOrderIcon style={{ width: "23px", height: "23px" }} />,
};

const messageTypes = {
  1: "info",
  2: "payed",
  3: "ends",
  4: "limit",
  5: "order-new",
};

const prioritetsTypes = {
  1: null,
  2: {
    label: "Потребує дії",
    circleStyle: {
      backgroundColor: "rgba(226, 112, 7, 1)",
    },
  },
  3: {
    label: "Терміновий",
    circleStyle: {
      backgroundColor: "rgba(255, 47, 43, 1)",
    },
  },
};

const Notification = (props) => {
  const { t } = useTranslation();

  const {
    item,
    notificationFilter,
    selectedRecords,
    addSelectedRecord,
    removeSelectedRecord,
    transferSigleRecordToArchive,
    transferSigleRecordToDelete,
    onRedirectToSingleOrder,
    onRedirectToSingleProduct,
  } = props;

  let messageContent = item.message;
  if (item.action === "Order" && item.notificationTypeId === 5) {
    messageContent = `Нове замовлення № ${item.actionArgs} чекає на підтвердження.`;
  }
  if (item.action === "Order" && item.notificationTypeId === 2) {
    messageContent = `Замовлення № ${item.actionArgs} проплачен и ждет формирования.`;
  }
  if (item.action === "Product" && item.notificationTypeId === 3) {
    messageContent = `Товар "${item.header}" закончился в магазине: ${item.shopName}`;
  }
  if (item.action === "Product" && item.notificationTypeId === 4) {
    messageContent = `Товар "${item.header}" закінчується в магазині: ${item.shopName}`;
  }

  const messagesIsDone = item.notificationStatusId === 2;
  const dirrefensIsMoreThanTwoDays =
    Math.abs(moment(item.created).diff(moment(), "hours")) > 48;
  const dirrefenceIsMoreThanWeek =
    Math.abs(moment(item.created).diff(moment(), "hours")) > 168;
  const messageCreateDate = dirrefensIsMoreThanTwoDays
    ? dirrefenceIsMoreThanWeek
      ? moment(item.created).locale("uk").tz("Europe/Kiev").format("DD.MM.YYYY")
      : moment(item.created).locale("uk").tz("Europe/Kiev").format("LLLL")
    : moment(item.created).locale("uk").tz("Europe/Kiev").calendar();

  const itemClassName = `item ${
    selectedRecords && selectedRecords.includes(item) ? "selected" : "selecting"
  } ${messagesIsDone ? "disabled" : ""}`;

  return (
    <div
      className={itemClassName}
      onClick={
        selectedRecords && selectedRecords.includes(item)
          ? () => removeSelectedRecord(item)
          : () => addSelectedRecord(item)
      }
      {...props}
    >
      <div className="notf-checkbox">
        <input
          type="checkbox"
          checked={selectedRecords && selectedRecords.includes(item)}
          onChange={
            selectedRecords && selectedRecords.includes(item)
              ? () => removeSelectedRecord(item)
              : () => addSelectedRecord(item)
          }
        />
      </div>
      <div className="notf-message-type">
        <div
          className={`mt-icon-div ${
            messagesIsDone ? "disabled" : messageTypes[item.notificationTypeId]
          }`}
        >
          {messageIcon[messageTypes[item.notificationTypeId]]}
        </div>
      </div>
      <div className="notf-message">
        <span
          className={`message ${
            !messagesIsDone &&
            (item.notificationTypeId === 5 ||
              item.notificationTypeId === 2 ||
              (item.action === "Product" && item.notificationTypeId === 4) ||
              (item.action === "Product" && item.notificationTypeId === 3))
              ? "active"
              : ""
          }`}
          onClick={
            !messagesIsDone &&
            (item.notificationTypeId === 5 || item.notificationTypeId === 2)
              ? (e) => {
                  e.stopPropagation();
                  onRedirectToSingleOrder(item);
                }
              : !messagesIsDone &&
                  (item.notificationTypeId === 4 ||
                    item.notificationTypeId === 3)
                ? (e) => {
                    e.stopPropagation();
                    onRedirectToSingleProduct(item);
                  }
                : null
          }
        >
          {messageContent}
        </span>
      </div>
      <div className="notf-prioritet">
        {prioritetsTypes[item.notificationPriorityId] && !messagesIsDone && (
          <div className="prioritet">
            <div
              className="circle"
              style={prioritetsTypes[item.notificationPriorityId].circleStyle}
            />
            <span>{prioritetsTypes[item.notificationPriorityId].label}</span>
          </div>
        )}
      </div>
      <div className="notf-date">
        <span className="date">{messageCreateDate}</span>
      </div>
      <div className="notf-setting" onClick={(e) => e.stopPropagation()}>
        {selectedRecords && selectedRecords.includes(item) && (
          <div
            className="icon"
            onClick={
              notificationFilter === "archive"
                ? () => transferSigleRecordToDelete(item)
                : () => transferSigleRecordToArchive(item)
            }
          >
            {notificationFilter === "archive" ? (
              <TrashGrayIcon />
            ) : (
              <ArchiveIcon />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
