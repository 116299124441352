
export function showError(error, description) {
  if (error instanceof Error) {
    console.error(error);
  }
  console.error(description);
}
export function copyObjOrArray(objOrArray) {
  return JSON.parse(JSON.stringify(objOrArray));
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    console.error(`String: ${str} - is not json`)
    return false;
  }
  return true;
}

// close popup by parent ID
export const closePopupByParentID = (parentId, setPopupIsOpened) => {
  const closePopupEvent = (ev) => {
    let parent = ev?.srcElement.parentElement;
    const parentsCollection = [parent];
    while (
      parent &&
      !!Object.keys(parent).length &&
      parent?.nodeName !== "BODY"
    ) {
      parent = parent.parentElement ? parent.parentElement : null;
      parent && parentsCollection.push(parent);
    }
    if (
      !parentsCollection.length ||
      (parentsCollection.length == 1 &&
        parentsCollection[parentsCollection.length - 1] == null) ||
      !parentsCollection?.find((el) => el.id.includes(parentId))
    ) {
      setPopupIsOpened(false);
    }
  };
  const listener = (ev) => closePopupEvent(ev);
  window.addEventListener("click", listener);

  // For removing listener
  return listener;
};

export const checkAreKeysExist = (obj, keyNames) => {
  let result = false;

  const check = (obj, keyNames) => {
    keyNames.forEach(function (keyName, index) {
      if (keyName in obj) {
        result = true;

        if (
          obj[keyName] &&
          typeof obj[keyName] === "object" &&
          keyNames[index + 1]
        ) {
          check(obj[keyName], keyNames.splice(1));
        }
      } else {
        result = false;
      }
    });
  };

  obj && typeof obj === "object" && check(obj, keyNames);
  return result;
};
